export enum InterviewStatuses {
    MATCHING = 'マッチング中',
    SCHEDULED = '面談予約済み',
    SELECTING_SPECIALIST = '専門家選択',
    SKIPPED = '未実施',
    NO_MATCH = 'マッチングなし',
    CANCELED = 'キャンセル',
    FINISHED = '面談済み',
    REQUESTING = '中企団相談窓口ご案内中',
    WAITING_CONF = '面談日程確認待ち',
    PAST_DUE = '回答期限切れ'
}

export enum MeetingStatuses {
    APPLIED = '面談申込中',
    SCHEDULED = '面談予約済み',
    WAITING = '日程選択待ち',
    FINISHED = '面談済み',
    CANCELED = 'キャンセル',
    PAST_DUE = '回答期限切れ'
}

export enum ExportTabs {
    EXPORT_DAIDO_EMPLOYEE_TAB = 1,
    EXPORT_CORP_USER_TAB = 2,
    EXPORT_KOUTEKI_TAB = 3,
    EXPORT_KISOKU_TAB = 4,
}

export enum UserStatus {
    DISABLED = -1,
    UNVERIFIED = 0,
    ACTIVE = 1,
}

export enum ConsultationStatuses {
    SCHEDULED = '面談予約済み',
    FINISHED = '面談済み',
    SKIPPED = '未実施',
    CANCELED = 'キャンセル',
    PAST_DUE = '回答期限切れ',
}

export enum ApplicationStatuses {
    INTERVIEW_SCHEDULED = '面談予約済み',
    INTERVIEWED = '面談済み',
    CANCELLED = 'キャンセル',
    ANSWER_EXPIRED = '回答期限切れ',
    NOT_IMPLEMENTED = '未実施',
}

export enum KanriDaidoRoleName {
    SUPER_ADMIN = 'kanri-daido-superadmin',
    ADMIN = 'kanri-daido-admin',
    EMPLOYEE = 'kanri-daido-employee',
}

export enum CalendarType {
    GRANTS = 1,
    SUBSIDY = 2,
    POLICY = 3
}
