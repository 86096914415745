import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { getKisokuByUser, getKotekiByUser } from 'api/meeting';

export type Pagination = {
    count: number;
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type MeetingRequest = {
    uuid: string,
    params: {
        status?: number,
        keywords?: string,
        per_page?: number,
        page?: number,
        sortBy?: string,
    },
}

type Label = {
    content: string;
    color: string;
};

export type Meeting = {
    uuid: string;
    title?: string[] | string;
    corporate_name: string;
    status: number;
    label: Label;
    corp_user_id: string;
    schedule_meeting?: string;
    schedule_applied?: string;
    created_at?: string;
    schedule?: string;
}

export const getKotekiMeeting = createAsyncThunk(
    'daido/employee/kouteki/meeting',
    async (params: MeetingRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getKotekiByUser(params);
            dispatch(setMeetingByUser(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getKisokuMeeting = createAsyncThunk(
    'daido/employee/kisoku/meeting',
    async (params: MeetingRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getKisokuByUser(params);
            dispatch(setMeetingByUser(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const meetingByUserSlice = createSlice({
    name: 'meetting-by-user',
    initialState: {
        loading: false,
        success: false,
        meeting: [] as Meeting[],
        pagination: {} as Pagination
    },
    reducers: {
        setMeetingByUser: (state, { payload }) => {
            state.meeting = payload.data;
            state.pagination = payload.pagination;
            state.pagination.perPage = payload.pagination.per_page;
            state.pagination.currentPage = payload.pagination.current_page;
            state.pagination.lastPage = payload.pagination.last_page;
        },
        resetMeetingByUser: (state) => {
            state.loading = true;
            state.meeting = [];
            state.pagination = {} as Pagination;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getKotekiMeeting.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getKotekiMeeting.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getKotekiMeeting.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.meeting = [];
        });
        builder.addCase(getKisokuMeeting.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getKisokuMeeting.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getKisokuMeeting.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.meeting = [];
        });
    }
});

export const { setMeetingByUser, resetMeetingByUser } = meetingByUserSlice.actions;
export const meetingByUserSelector = (state: RootState) => state.meetingByUser;
