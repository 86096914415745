import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';

type Props = {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
    handleREason: (value: string) => void;
};

const RejectModal = (props: Props) => {
    const { open, onSubmit, onClose, handleREason } = props;

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        handleREason(e.target.value);
    }

    return (
        <Modal className='general-cancel-modal' width={800} visible={open} footer={null} onCancel={onClose}>
            <div className='title-modal'>面談予約　キャンセル</div>
            <div className='subtitle-modal'>キャンセルする理由をご入力ください。（必須）</div>
            <Input.TextArea rows={6} placeholder='キャンセルする理由をご入力ください。' maxLength={500} onChange={onChange} />
            <div className='form-buttons'>
                <Button className='general-button cancel-button me-4' onClick={onClose}>
                    <span className='me-3'>
                        <CloseOutlined />
                    </span>
                    閉じる
                </Button>
                <Button className='general-button submit-button' onClick={onSubmit}>
                    確認画面に進む
                </Button>
            </div>
        </Modal>
    );
};

export default RejectModal;
