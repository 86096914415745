import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { ConfirmRequest } from 'app/slice/kisokuSlice';
import React from 'react';

type Props = {
    open: boolean;
    reject: ConfirmRequest;
    cancel: boolean | null;
    onClose: () => void;
};
function MessageModal(props: Props) {
    const { open, reject, cancel, onClose } = props;

    return (
        <Modal className='general-cancel-modal' width={800} visible={open} footer={null} onCancel={onClose}>
            <div className='title-modal'>
                {cancel === true && '面談予約をキャンセルしました'}
                {cancel === false && '面談予約のキャンセルに失敗しました'}
            </div>
            <div className='cancel-info'>
                <div className='w-25 p-3 cancel-info-left'>公的資金名称</div>
                <div className='w-75 p-3'>{reject.title}</div>
            </div>
            <div className='cancel-info border-bottom'>
                <div className='w-25 p-3 cancel-info-left'>面談日時</div>
                <div className='w-75 p-3'>{reject.datetime}</div>
            </div>
            <div className='form-buttons mt-4 justify-content-center'>
                <Button className='general-button cancel-button' onClick={onClose}>
                    <span className='me-3'>
                        <CloseOutlined />
                    </span>
                    閉じる
                </Button>
            </div>
        </Modal>
    );
}

export default MessageModal;
