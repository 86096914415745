import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMeetingDetailsApi } from "api/meetingDetails";
import { RootState } from "app/store";
import { GetMeetingDetailsRequest } from "./kisokuMeetingDetailsSlice";

export const GET_KOUTEKI_MEETING_DETAILS = 'GET_KOUTEKI_MEETING_DETAILS';

// Kouteki Meetings Details State
type KoutekiMeetingDetailsState = {
    success: boolean | null,
    loading: boolean,
    data: KoutekiMeetingDetails,
    type: string
}

export type KoutekiMeetingDetails = {
    uuid: string;
    person_in_charge: string | null;
    date_created: string;
    status: string;
    read_status: number;
    type: string;
    specialist_schedule_label: string;
    company: Company;
    funds: Fund[];
    meeting: Meeting;
    cancellation_reason: string | null;
    cancellation_date: string | null;
};

type Company = {
    name: string;
    address: string;
    industry: string;
    labor_regulations_compliant: number;
    allow_sharoushi: number;
    allow_shindanshi: number;
    insurances: Insurances[];
    person_in_charge: string;
    prefecture: string;
    employee_count_ranges: string;
    parttime_employee_count: number;
};

type Insurances = {
    code: string;
    name: string;
    created_at: string | null;
    updated_at: string | null;
    pivot: {
        company_dd: number;
        insurance_plan_dd: number;
    };
};

type Fund = {
    code: string;
    type: number;
    title: string;
    fund_requirements: FundRequirements[];
    application_remarks: string;
};

type FundRequirements = {
    title: string;
    requirements: {
        content: string;
    }[];
};

type Meeting = {
    zoom_id: string;
    join_url: string;
    daido_joins: number;
    password: string;
    emergency_contact_number: string | null;
    meeting_remarks: string;
    schedule: string;
    schedule_datetime: string;
    unimplemented_comments: string;
};

/**
 * GET Kouteki meeting details by customerUuid and applicationUuid
 **/
export const getKoutekiMeetingDetails = createAsyncThunk('/company/applications/details', async (data: GetMeetingDetailsRequest, { dispatch, rejectWithValue }) => {
    try {
        const response = await getMeetingDetailsApi(data);
        if (response.data.success === true) {
            dispatch(setKoutekiMeetingDetails(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
},
);

/**
 * Create Kisoku Meeting Details Slice
 */
export const koutekiMeetingDetailsSlice = createSlice({
    name: 'application',
    initialState: {
        success: false,
        loading: false,
        data: {} as KoutekiMeetingDetails,
        type: '',
    } as KoutekiMeetingDetailsState,
    reducers: {
        reset: (state: KoutekiMeetingDetailsState) => {
            state.success = false;
            state.loading = true;
            state.data = {} as KoutekiMeetingDetails;
            state.type = '';
        },
        setKoutekiMeetingDetails: (state, { payload }) => {
            state.data = payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getKoutekiMeetingDetails.pending, (state: KoutekiMeetingDetailsState) => {
            state.type = GET_KOUTEKI_MEETING_DETAILS;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(getKoutekiMeetingDetails.rejected, (state: KoutekiMeetingDetailsState) => {
            state.type = GET_KOUTEKI_MEETING_DETAILS;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getKoutekiMeetingDetails.fulfilled, (state: KoutekiMeetingDetailsState) => {
            state.type = GET_KOUTEKI_MEETING_DETAILS;
            state.loading = false;
            state.success = false;
        });
    },
});

export const { setKoutekiMeetingDetails } = koutekiMeetingDetailsSlice.actions;
export const selectKoutekiMeetingDetails = (state: RootState) => state.koutekiMeetingDetails;