import React, { useEffect, useState } from 'react';
import { CopyOutlined, DownOutlined, LeftOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, message, Row } from 'antd';
import { ApplicationStatuses, KanriDaidoRoleName } from 'enums';
import KisokuCancel from 'components/dashboard/cancelModal/kisoku';
import { getKisokuMeetingDetails, selectKisokuMeetingDetails } from 'app/slice/kisokuMeetingDetailsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatDate, scrollToTop } from 'lib/utils';
import ChangeScheduleModal from 'components/common/ChangeScheduleModal';
import { authSelector } from 'app/slice/authSlice';

type LocationState = {
    searchParams: string;
};

const KisokuMeetingDetailPage = () => {
    const { data } = useAppSelector(selectKisokuMeetingDetails);
    const { roleName } = useAppSelector(authSelector);
    const { customerUuid, applicationUuid } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const locationState = useLocation().state as LocationState;
    const [messageApi, contextHolder] = message.useMessage();
    const [showTopics, setShowTopics] = useState(false);
    const dateCreated = data?.date_created;
    const formattedDateCreated = formatDate(dateCreated);
    const cancellationDate = data?.cancellation_date;
    const formattedCancellationDate = formatDate(cancellationDate);
    const [isChangeScheduleModalVisible, setIsChangeScheduleModalVisible] = useState(false);

    // Get Kisoku meeting details based on customer uuid and application uuid.
    useEffect(() => {
        if (customerUuid && applicationUuid) {
            dispatch(getKisokuMeetingDetails({ customerUuid, applicationUuid }));
        }
    }, [customerUuid, applicationUuid, dispatch]);

    // Scroll to top on navigate
    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const toggleShowTopics = () => {
        setShowTopics(!showTopics);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(data?.meeting?.join_url || '').then(() => messageApi.info('URLをコピーしました'));
    };

    const goBackToKisokuMeetingList = () => {
        //navigate('/dashboard/kisoku');
        navigate('/dashboard/kisoku' + (locationState?.searchParams ?? ''));
    };

    const getStatusClass = (content: string) => {
        let className = '';
        switch (content) {
            case ApplicationStatuses.INTERVIEW_SCHEDULED:
                className += 'scheduled';
                break;
            case ApplicationStatuses.INTERVIEWED:
                className += 'interviewed';
                break;
            case ApplicationStatuses.CANCELLED:
                className += 'cancelled';
                break;
            case ApplicationStatuses.NOT_IMPLEMENTED:
                className += 'not-implemented';
                break;
            default:
                className += 'default';
                break;
        }

        return className;
    };

    const openChangeScheduleModal = () => {
        setIsChangeScheduleModalVisible(true);
    };

    const onCloseChangeScheduleModal = () => setIsChangeScheduleModalVisible(false);

    return (
        <>
            <div className='meeting-details'>
                <div className={'d-flex row w-100'}>
                    <div className='meeting-details-title'>
                        <strong>面談詳細</strong>
                    </div>
                    <div
                        className={`card-container card-container-${getStatusClass(data?.status)} meeting-details-card`}
                    >
                        <strong className={`status-${getStatusClass(data?.status)} meeting-details-status`}>
                            {data?.status}
                        </strong>
                    </div>
                </div>

                <div className={'d-flex row align-self-center'}>
                    <Button
                        onClick={goBackToKisokuMeetingList}
                        shape={'round'}
                        className='round-button meeting-details-back-button'
                        htmlType='button'
                    >
                        <LeftOutlined />
                        面談一覧へ戻る
                    </Button>
                </div>
            </div>

            {contextHolder}
            <div className={'d-flex row justify-content-sm-end mt-4'}>
                <span>面談依頼日：{formattedDateCreated}</span>
            </div>

            <div className={'d-flex flex-column'}>
                <div className='mt-2 meeting-details-div-top'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            面談対象規程
                        </Col>

                        <Col span={18} className='meeting-details-grant-title'>
                            {data?.type}
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            面談日時
                        </Col>

                        <Col span={18} className='meeting-details-date'>
                            {data?.meeting?.schedule_datetime}
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            ミーティングURL
                        </Col>

                        <Col span={18} className='meeting-details-value'>
                            <Row>
                                {data?.status === ApplicationStatuses.INTERVIEWED ? (
                                    <Col span={24}>本面談は終了いたしました</Col>
                                ) : data?.status === ApplicationStatuses.CANCELLED ? (
                                    <Col span={24}>本面談はキャンセルとなりました</Col>
                                ) : data?.status === ApplicationStatuses.NOT_IMPLEMENTED ? (
                                    <Col span={24}>
                                        ご予約のお時間にZoomへの入室がなかったため、本面談はキャンセルとなりました
                                    </Col>
                                ) : (
                                    data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED && (
                                        <>
                                            <Col span={18}>
                                                <a href={data?.meeting?.join_url} target='_blank' rel='noreferrer'>
                                                    {data?.meeting?.join_url}
                                                </a>
                                            </Col>
                                            <Col span={4} className='copy-clipboard' onClick={copyToClipboard}>
                                                <CopyOutlined className='copy-icon' /> URLをコピー
                                            </Col>
                                        </>
                                    )
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            入室パスワード
                        </Col>
                        {data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED ? (
                            <Col span={18} className='meeting-details-value'>
                                {data?.meeting?.password}
                            </Col>
                        ) : (
                            <Col span={18} className='meeting-details-value'>
                                ー
                            </Col>
                        )}
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            面談のご案内
                        </Col>
                        <Col span={18} className='meeting-details-value'>
                            <div className={'d-flex flex-column'}>
                                <div className={'d-flex'}>
                                    <div>※</div>
                                    <div>本面談は「社会保険労務士」または「中小企業診断士」がご対応いたします。</div>
                                </div>
                                <div className={'d-flex'}>
                                    <div>※</div>
                                    <div>面談ルームへの入室は予約時刻の５分前から可能です。</div>
                                </div>
                                <div className={'d-flex'}>
                                    <div>※</div>
                                    <div>
                                        面談時間は予約時刻から３０分間です。３０分を過ぎた場合は予告なく面談終了となる可能性がござい
                                        ます。予めご了承ください。
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            大同生命担当者
                        </Col>

                        <Col span={18} className='meeting-details-value'>
                            {data?.meeting?.daido_joins ? '同席を希望する' : '同席を希望しない'}
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-background mt-4 mb-4'>
                    <div className={'d-flex row py-3 meeting-details-border'}>
                        <div className={'mx-3 w-25 meeting-details-discussion-title'}>特にご相談したいこと</div>
                        <div className={'d-flex row mx-3 w-50'}>
                            <div className={'d-flex'}>上記公的資金の診断の際に選択・チェックされた項目です。</div>
                        </div>
                        <div className={'d-flex row align-self-center w-25 justify-content-sm-end mx-4'}>
                            <Button
                                shape={'round'}
                                onClick={toggleShowTopics}
                                className='round-button meeting-details-accordion-button'
                                htmlType='button'
                            >
                                詳細を表示{showTopics ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                        </div>
                    </div>
                    {showTopics && (
                        <div className={'d-flex flex-column mx-3 mb-4 meeting-details-requirements'}>
                            <div className={'d-flex row'}>
                                <ul>
                                    {data?.topics?.map((tp, index) => (
                                        <li key={index}>{tp?.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>

                {/* Meeting details inputted by user on meeting schedule page. */}
                <div className={'d-flex flex-column border mb-4 meeting-details-border'}>
                    <div className={'pt-3 mx-3 w-25 meeting-details-remarks-title'}>ご相談内容</div>
                    <div className={'d-flex row py-3 mx-3'}>{data?.meeting?.meeting_remarks || 'ー'}</div>
                </div>

                {/* Comment inputted by corp users when they cancelled the meeting. */}
                {data?.status === ApplicationStatuses.CANCELLED && (
                    <div className={'d-flex flex-column border mt-2 mb-2 meeting-details-border'}>
                        <div className={'d-flex row'}>
                            <div className={'d-flex pt-3 row mx-3 w-75 meeting-details-remarks-title'}>
                                キャンセル理由
                            </div>
                            <div className={'d-flex row align-self-center w-25 justify-content-sm-end mx-4'}>
                                <span>キャンセルした日：{formattedCancellationDate}</span>
                            </div>
                        </div>
                        <div className={'d-flex row py-3 mx-3'}>{data?.cancellation_reason || 'ー'}</div>
                    </div>
                )}

                {/* Show only when role name is super admin and status is interview scheduled */}
                {roleName === KanriDaidoRoleName.SUPER_ADMIN &&
                    data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED && (
                        <div className='meeting-details-action-buttons'>
                            <Row>
                                <Button
                                    onClick={openChangeScheduleModal}
                                    shape={'round'}
                                    className='round-button meeting-details-change-schedule-btn'
                                    htmlType='button'
                                >
                                    予約日程を変更する
                                </Button>

                                <KisokuCancel
                                    uuid={applicationUuid ?? ''}
                                    customerUuid={customerUuid ?? ''}
                                    data={data}
                                />
                            </Row>
                        </div>
                    )}
            </div>

            {/* Modal to change Kisoku meeting schedule */}
            <ChangeScheduleModal
                isVisible={isChangeScheduleModalVisible}
                title='面談予約 日程変更'
                reasonText={'・変更を希望する面談日時を、下のカレンダーから新たに選択してください。\n ・面談予約枠は、本日より7営業日以降の日程から選択可能です。'
                }
                uuid={data?.uuid}
                oldSchedule={data?.meeting?.schedule_datetime}
                onClose={onCloseChangeScheduleModal}
                onOpenChangeScheduleModal={openChangeScheduleModal}
            />
        </>
    );
};

export default KisokuMeetingDetailPage;
