import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import RejectModal from './reject';
import ConfirmModal from './confirm';
import MessageModal from './message';
import { cancelMeeting, ConfirmRequest, kisokuMeetingSelector, resetAfterCancel } from 'app/slice/kisokuSlice';
import { getKisokuMeetingDetails, KisokuMeetingDetails } from 'app/slice/kisokuMeetingDetailsSlice';

type Props = {
    uuid: string;
    customerUuid: string;
    data: KisokuMeetingDetails;
}

const KisokuCancel = (props: Props) => {
    const { uuid, customerUuid, data } = props;
    const dispatch = useDispatch();
    const { cancel, loading } = useSelector(kisokuMeetingSelector);
    const [isOpen, setIsOpen] = useState({
        reject: false,
        confirm: false,
        message: false
    });
    const [reject, setReject] = useState<ConfirmRequest>({
        title: '',
        datetime: '',
        reason: '',
    });

    useEffect(() => {
        dispatch(resetAfterCancel());
    }, []);

    useEffect(() => {
        setReject({
            title: data?.type,
            datetime: data?.meeting?.schedule,
            reason: '',
        });
    }, [data]);

    useEffect(() => {
        if (cancel) {
            setIsOpen({ ...isOpen, confirm: false, message: true });
        }
    }, [cancel]);

    const handleREason = (value: string) => {
        setReject({ ...reject, reason: value });
    }

    const showModal = () => {

        setIsOpen({ ...isOpen, reject: true });
    };

    const onReject = () => {
        setIsOpen({ ...isOpen, reject: false, confirm: true });
    };

    const onConfirm = () => {
        dispatch(cancelMeeting({
            uuid: uuid,
            reason: reject.reason
        }));
        setIsOpen({ ...isOpen, confirm: false, message: true });
    };

    const onBack = () => {
        setIsOpen({ ...isOpen, reject: true, confirm: false });
    };

    const onClose = () => {
        setIsOpen({
            reject: false,
            confirm: false,
            message: false
        });
        setReject({
            title: '',
            datetime: '',
            reason: '',
        });
        dispatch(getKisokuMeetingDetails({
            customerUuid: customerUuid,
            applicationUuid : uuid
        }));
    };

    return (
        <>
            <Button onClick={showModal} shape={'round'} className='round-button meeting-details-cancel-btn' htmlType='button'>
                予約をキャンセルする
            </Button>
            <RejectModal open={isOpen.reject} onSubmit={onReject} onClose={onClose} handleREason={handleREason} />
            <ConfirmModal open={isOpen.confirm} reject={reject} loading={loading} onSubmit={onConfirm} onClose={onClose} onBack={onBack} type={'kisoku'} />
            <MessageModal open={isOpen.message} reject={reject} cancel={cancel} onClose={onClose} />
        </>
    );
};

export default KisokuCancel;
