import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { ConfirmRequest } from 'app/slice/kisokuSlice';

type Props = {
    open: boolean;
    reject: ConfirmRequest;
    loading: boolean;
    onSubmit: () => void;
    onClose: () => void;
    onBack: () => void;
    type: string;
};

function ConfirmModal(props: Props) {
    const { open, reject, onSubmit, onClose, onBack, type } = props;

    return (
        <Modal className='general-cancel-modal' width={800} visible={open} footer={null} onCancel={onClose}>
            <div className='title-modal'>下記の面談予約をキャンセルします</div>
            <div className='cancel-info'>
                <div className='w-25 p-2 p-md-3 cancel-info-left'>
                    {type === 'kisoku' ? '面談対象規程' : '公的資金名称'}
                </div>
                <div className='w-75 p-2 p-md-3'>
                    {reject.title.split('\n').map(fund => <div>{fund}</div>)}
                </div>
            </div>
            <div className='cancel-info'>
                <div className='w-25 p-2 p-md-3 cancel-info-left'>面談日時</div>
                <div className='w-75 p-2 p-md-3'>{reject.datetime}</div>
            </div>
            <div className='cancel-info border-bottom'>
                <div className='w-25 p-2 p-md-3 cancel-info-left'>キャンセル理由</div>
                <div className='w-75 p-2 p-md-3'>{reject.reason}</div>
            </div>
            <div className='form-buttons mt-4'>
                <Button className='general-button cancel-button me-4' onClick={onBack}>
                    <span className='me-3'>
                        <LeftOutlined />
                    </span>
                    戻る
                </Button>
                <Button className='general-button submit-button' onClick={onSubmit}>
                    キャンセルを確定する
                </Button>
            </div>
        </Modal>
    );
}

export default ConfirmModal;
