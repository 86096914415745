import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getKoutekiMeetingDetails, selectKoutekiMeetingDetails } from 'app/slice/koutekiMeetingDetailsSlice';
import KoutekiMeetingDetailsHeader from 'components/common/meeting_detail_page/KoutekiMeetingDetailsHeader';
import KoutekiMeetingDetailsBody from 'components/common/meeting_detail_page/KoutekiMeetingDetailsBody';

const KoutekiMeetingDetailsCorp = () => {
    const { data } = useAppSelector(selectKoutekiMeetingDetails);
    const { customerUuid, applicationUuid } = useParams();
    const dispatch = useAppDispatch();

    // Get kouteki meeting details based on customer uuid and application uuid.
    useEffect(() => {
        if (customerUuid && applicationUuid) {
            dispatch(getKoutekiMeetingDetails({ customerUuid, applicationUuid }));
        }
    }, [customerUuid, applicationUuid, dispatch]);

    return (
        <>
            <KoutekiMeetingDetailsHeader applicationStatus={data.status} />
            <KoutekiMeetingDetailsBody data={data} />
        </>
    );
};

export default KoutekiMeetingDetailsCorp;
