import { getAxiosClientWithToken } from './index';
import {FetchKisokuMeetingRequest,} from "../types";
import { CancelRequest } from 'app/slice/kisokuSlice';

export const getKisokukiMeetings = (params: FetchKisokuMeetingRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/wr-meeting', {params});
};

export const cancelMeetingApi = (request: CancelRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/daido/application/${request.uuid}/cancel`, {reason: request.reason});
};