import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { getKisokuMeetingDetails } from 'app/slice/kisokuMeetingDetailsSlice';
import { getKoutekiMeetingDetails } from 'app/slice/koutekiMeetingDetailsSlice';
import { useParams } from 'react-router-dom';

type Props = {
    isVisible: boolean;
    title: string;
    header: string;
    subHeader: string;
    newScheduleLabel: string;
    fundType?: number;
    onClose: () => void;
};
const NewScheduleModal = (props: Props) => {
    const { customerUuid, applicationUuid } = useParams();
    const dispatch = useAppDispatch();
    const { isVisible, title, header, subHeader, newScheduleLabel, fundType, onClose } = props;

    const onCloseNewScheduleModal = () => {
        //Automatically update the meeting detail page schedule date and time.
        if (customerUuid && applicationUuid) {
            if(fundType === 4 || fundType === 3) {
                dispatch(getKoutekiMeetingDetails({ customerUuid, applicationUuid }));
            } else {
                dispatch(getKisokuMeetingDetails({ customerUuid, applicationUuid }));
            }
        }
        onClose();
    };

    return (
        <>
            <Modal
                className={'new-schedule-modal'}
                visible={isVisible}
                footer={null}
                onCancel={onClose}
                maskClosable={false}
            >
                <div className='title'>{title}</div>

                <div className='subtitle'>
                    <p className='subtitle-top'>{header}</p>
                    <p className='subtitle-bottom'> {subHeader}</p>
                </div>

                <div className='new-schedule-div'>
                    <Row className='new-schedule-row'>
                        <Col span={8} className='new-schedule-label'>
                            変更後
                        </Col>

                        <Col span={16} className='new-schedule-date'>
                            {newScheduleLabel}
                        </Col>
                    </Row>
                </div>

                <div className='action-button'>
                    <Button className='btn-close' onClick={onCloseNewScheduleModal} htmlType='button'>
                        <h3 className='btn-close-text'>
                            <CloseOutlined className='btn-icon' />
                            閉じる
                        </h3>
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default NewScheduleModal;
