import 'assets/styles/app.scss';
import 'moment/locale/ja';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { authSelector, fetchUserData } from 'app/slice/authSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import ChangePasswordModal from 'pages/Dashboard/Management/ChangePasswordModal';
import DashboardLayout from 'pages/Dashboard/Management/DashboardLayout';
import ForgotPasswordModal from 'components/landing/ForgotPasswordModal';
import Loader from 'components/common/Loader';
import MainLayout from 'components/MainLayout';
import RegisterLayout from 'components/RegisterLayout';
import SuccessModal from 'components/common/SuccessModal';
import { reset } from 'app/slice/forgotPasswordSlice';
import KisokuTemplate from 'pages/Dashboard/KisokuTemplate';
import KisokuTemplateDetails from 'pages/Dashboard/KisokuTemplate/KisokuTemplateDetails';
import KoutekiMeetingDetailPage from 'pages/Dashboard/Kouteki/KoutekiMeetingDetailPage';
import KisokuMeetingDetailPage from 'pages/Dashboard/Kisoku/KisokuMeetingDetailPage';
import KoutekiMeetingDetailsCorp from 'pages/Dashboard/Customer/corp_user/KoutekiMeetingDetailsCorp';
import KisokuMeetingDetailsCorp from 'pages/Dashboard/Customer/corp_user/KisokuMeetingDetailsCorp';

const ALL_ROLES = ['kanri-daido-superadmin', 'kanri-daido-admin', 'kanri-daido-employee'];
const SUPER_ADMIN_ONLY = ['kanri-daido-superadmin'];
const SUPER_ADMIN_ADMIN_ONLY = ['kanri-daido-superadmin', 'kanri-daido-admin'];
const EMPLOYEE_ONLY = ['kanri-daido-employee'];

// somehow react lazy doesn't work well with HOC, need to find a work-around first
// typescript bug I guess
const Home = lazy(() => import('pages/Home'));
const Management = lazy(() => import('pages/Dashboard/Management'));
const ForgotPassword = lazy(() => import('pages/Dashboard/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('pages/Dashboard/ForgotPasswordSuccess'));
const DepartmentManagementList = lazy(() => import('pages/Dashboard/DepartmentManagement'));
const BranchManagement = lazy(() => import('pages/Dashboard/BranchManagement'));
const NoPage = lazy(() => import('pages/Dashboard/NoPage'));
const EmployeeManagement = lazy(() => import('pages/Dashboard/EmployeeManagement'));
const EmployeeDetails = lazy(() => import('pages/Dashboard/EmployeeManagement/EmployeeDetails'));
const EmployeeEditDetails = lazy(() => import('pages/Dashboard/EmployeeManagement/EmployeeEditDetails'));
const Profile = lazy(() => import('pages/Dashboard/Profile'));
const Customer = lazy(() => import('pages/Dashboard/Customer'));
const ViewCustomer = lazy(() => import('pages/Dashboard/Customer/ViewCustomer'));
const ConsultationDetail = lazy(() => import('pages/Dashboard/ConsultationDetails'));
const Branch = lazy(() => import('pages/Dashboard/BranchManagement'));
const EmployeeRegistration = lazy(() => import('pages/EmployeeRegistration'));
const EmployeeRegistrationFinished = lazy(() => import('pages/EmployeeRegistration/RegistrationFinished'));
const DiagnosticHistory = lazy(() => import('pages/Dashboard/DiagnosticHistory'));
const DiagnosticHistoryKisoku = lazy(() => import('pages/Dashboard/DiagnosticHistoryKisoku'));
const DiagnosticHistoryDetails = lazy(() => import('pages/Dashboard/DiagnosticHistory/DiagnosticHistoryDetails'));
const Kouteki = lazy(() => import('pages/Dashboard/Kouteki'));
const Kisoku = lazy(() => import('pages/Dashboard/Kisoku'));
const Export = lazy(() => import('pages/Dashboard/Export'));
const Service = lazy(() => import('pages/Dashboard/Service'));

const App = () => {
    const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
    const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

    const { token } = useAppSelector(authSelector);
    const dispatch = useAppDispatch();

    const onCloseForgotPasswordModal = () => {
        setIsForgotPasswordModalVisible(false);
        dispatch(reset());
    };

    const onOpenForgotPasswordModal = () => {
        setIsForgotPasswordModalVisible(true);
    };

    const onOpenChangePasswordModal = () => {
        setIsChangePasswordVisible(true);
    };

    const onCloseChangePasswordModal = () => {
        setIsChangePasswordVisible(false);
    };

    useEffect(() => {
        if (token) {
            dispatch(fetchUserData());
        }
    }, [token, dispatch]);

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader force={true} />}>
                <Routes>
                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Home onOpenForgotPasswordModal={onOpenForgotPasswordModal} />} />
                        <Route path='forgot-password/:token/success' element={<ForgotPasswordSuccess />} />
                        <Route path='forgot-password/:token' element={<ForgotPassword />} />
                    </Route>
                    <Route path='/employee' element={<RegisterLayout />}>
                        <Route path='registration' element={<EmployeeRegistration />} />
                        <Route path='registration/:uuid' element={<EmployeeRegistration />} />
                        <Route path='registration/success' element={<EmployeeRegistrationFinished />} />
                    </Route>
                    <Route
                        path='/dashboard'
                        element={<DashboardLayout onOpenChangePasswordModal={onOpenChangePasswordModal} />}
                    >
                        <Route path='management' element={<Management allowedRoles={ALL_ROLES} />} />
                        <Route path='history' element={<DiagnosticHistory allowedRoles={ALL_ROLES} />}>
                            <Route path=':year/:month' element={<DiagnosticHistoryDetails />} />
                        </Route>
                        <Route path='history/kisoku' element={<DiagnosticHistoryKisoku allowedRoles={ALL_ROLES} />} />
                        <Route path='kisoku-template' element={<KisokuTemplate allowedRoles={SUPER_ADMIN_ONLY} />}>
                            <Route path=':year/:month' element={<KisokuTemplateDetails />} />
                        </Route>
                        <Route path='branch' element={<BranchManagement allowedRoles={SUPER_ADMIN_ONLY} />} />
                        <Route
                            path='department'
                            element={<DepartmentManagementList allowedRoles={SUPER_ADMIN_ONLY} />}
                        />
                        <Route path='employee' element={<EmployeeManagement allowedRoles={ALL_ROLES} />}>
                            <Route path='details' element={<EmployeeDetails />} />
                            <Route path='edit' element={<EmployeeEditDetails />} />
                        </Route>

                        <Route path='kouteki' element={<Kouteki allowedRoles={ALL_ROLES} />}>
                            <Route path=':customerUuid/consultation/:applicationUuid'>
                                {/* New route for kouteki detail page */}
                                <Route index element={<KoutekiMeetingDetailPage />} />
                                {/* <Route
                                    index
                                    element={<ConsultationDetail type='koutekiDetail' allowedRoles={ALL_ROLES} />}
                                /> */}
                                <Route
                                    path=':specialistUuid/schedule'
                                    element={<ConsultationDetail type='koutekiDetail' allowedRoles={ALL_ROLES} />}
                                />
                            </Route>
                        </Route>
                        <Route path='kisoku' element={<Kisoku allowedRoles={ALL_ROLES} />}>
                            <Route path=':customerUuid/consultation/:applicationUuid'>
                                {/* New route for kouteki detail page */}
                                <Route index element={<KisokuMeetingDetailPage />} />
                                {/* <Route
                                path=':customerUuid/consultation/:applicationUuid'
                                element={<ConsultationDetail type='kisokuDetail' allowedRoles={ALL_ROLES} />}
                                /> */}
                            </Route>
                        </Route>

                        <Route
                            path='profile'
                            element={<Profile onOpenChangePasswordModal={onOpenChangePasswordModal} />}
                        />
                        <Route path='branch' element={<Branch allowedRoles={ALL_ROLES} />} />
                        {/* New routing path for customer */}
                        <Route path='customer'>
                            <Route index element={<Customer allowedRoles={ALL_ROLES} />} />
                            <Route path=':uuid' element={<ViewCustomer />} />
                            <Route
                                path=':customerUuid/consultation/koteki/:applicationUuid'
                                element={<KoutekiMeetingDetailsCorp />}
                            />
                            <Route
                                path=':customerUuid/consultation/kisoku/:applicationUuid'
                                element={<KisokuMeetingDetailsCorp />}
                            />
                        </Route>
                        {/* Old routing path for customer */}
                        {/* <Route path='customer' element={<Customer allowedRoles={ALL_ROLES} />}>
                            <Route path=':uuid' element={<ViewCustomer />} />
                            <Route path=':customerUuid/consultation/:applicationUuid'>
                                <Route
                                    index
                                    element={<ConsultationDetail type='customerDetail' allowedRoles={ALL_ROLES} />}
                                />
                                <Route
                                    path=':specialistUuid/schedule'
                                    element={<ConsultationDetail type='customerDetail' allowedRoles={ALL_ROLES} />}
                                />
                            </Route>
                        </Route> */}

                        <Route path='export' element={<Export allowedRoles={SUPER_ADMIN_ADMIN_ONLY} />} />
                        <Route path='services' element={<Service allowedRoles={EMPLOYEE_ONLY} />} />
                        <Route path='*' element={<NoPage />} />
                    </Route>
                </Routes>
            </Suspense>
            <ChangePasswordModal isVisible={isChangePasswordVisible} onClose={onCloseChangePasswordModal} />
            <ForgotPasswordModal isVisible={isForgotPasswordModalVisible} onClose={onCloseForgotPasswordModal} />
            <SuccessModal />
        </BrowserRouter>
    );
};

export default App;
