import { MeetingRequest } from 'app/slice/meetingByUserSlice';
import { getAxiosClientWithToken } from './index';

export const getScheduledMeetings = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/company/scheduled/meetings');
};

export const getKotekiByUser = (request: MeetingRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/applications/company/${request.uuid}/koteki-meeting`, { params: request?.params });
};

export const getKisokuByUser = (request: MeetingRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/work-regulation-meetings/${request.uuid}`, { params: request.params });
};
