import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ApplicationStatuses } from 'enums';
import { useNavigate } from 'react-router-dom';

type Props = {
    applicationStatus: string;
};

const KoutekiMeetingDetailsHeader = (props: Props) => {
    const { applicationStatus } = props;
    const navigate = useNavigate();

    const getStatusClass = (content: string) => {
        let className = '';
        switch (content) {
            case ApplicationStatuses.INTERVIEW_SCHEDULED:
                className += 'scheduled';
                break;
            case ApplicationStatuses.INTERVIEWED:
                className += 'interviewed';
                break;
            case ApplicationStatuses.CANCELLED:
                className += 'cancelled';
                break;
            case ApplicationStatuses.NOT_IMPLEMENTED:
                className += 'not-implemented';
                break;
            default:
                className += 'default';
                break;
        }

        return className;
    };

    const goBackToKoutekiMeetingList = () => {
        //Go back to meeting list page: if user access detail page after searching or sorting, they should go back the list page after searching or sorting.
        navigate(-1);
    };

    return (
        <div className='meeting-details'>
            <div className={'d-flex row w-100'}>
                <div className='meeting-details-title'>
                    <strong>面談詳細</strong>
                </div>
                <div
                    className={`card-container card-container-${getStatusClass(
                        applicationStatus
                    )} meeting-details-card`}
                >
                    <strong className={`status-${getStatusClass(applicationStatus)} meeting-details-status`}>
                        {applicationStatus}
                    </strong>
                </div>
            </div>

            <div className={'d-flex row align-self-center'}>
                <Button
                    onClick={goBackToKoutekiMeetingList}
                    shape={'round'}
                    className='round-button meeting-details-back-button'
                    htmlType='button'
                >
                    <LeftOutlined />
                    面談一覧へ戻る
                </Button>
            </div>
        </div>
    );
};
export default KoutekiMeetingDetailsHeader;
