import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { cancellationConsultation, getConsultationDetail, GetConsultationDetailRequest } from 'api/consultation';
import { getErrorMessage } from 'api';
import { getSpecialistProfileApi, scheduleConsultationApi } from 'api/application';
import { ConsultationStatuses } from 'pages/Dashboard/ConsultationDetails';

type ConsultationState = {
    consultationDetail: ConsultationDetailState,
    cancellationConsultation: CancellationConsultationState,
    specialistProfile: SpecialistProfileState,
    scheduleConsultation: ScheduleConsultationState
};

type CancellationConsultationState = {
    loading: boolean,
    success: boolean,
    error: boolean,
    messages: string | CancellationValidateMessage
}

export type CancellationValidateMessage = {
    reason: string | string[]
}

type SpecialistProfileState = {
    loading: boolean,
    success: boolean,
    error: boolean,
    profile: SpecialistProfileData,
}

type ScheduleConsultationState = {
    loading: boolean,
    success: boolean,
    error: boolean,
}

export type ConsultationDetailPageProps = {
    consultation?: Consultation,
    customerUuid?: string,
    roleName: string,
    onCancellation?: (values: CancellationConsultationRequest) => void,
    isCancelModalVisible?: boolean,
    onCloseCancelModal?: () => void,
    onOpenCancelModal?: () => void,
    type: ConsultationDetailType,
};

export type ConsultationDetailType = 'customerDetail' | 'koutekiDetail' | 'kisokuDetail';

type ConsultationDetailState = {
    loading: boolean,
    success: boolean,
    error: boolean,
    consultation: Consultation,
}

type CompanyData = {
    name: string,
    address: string,
    industry: string,
    labor_regulations_compliant: number,
    allow_sharoushi: 0 | 1,
    allow_shindanshi: 0 | 1,
    has_company_insurance: string,
    person_in_charge?: string,
}

type Company = {
    name: string,
    address: string,
    industry: string,
    laborRegulationsCompliant: number,
    allowSharoushi: 0 | 1,
    allowShindanshi: 0 | 1,
    hasCompanyInsurance: string,
    personInCharge?: string,
}

export type Fund = {
    code: string,
    type: number,
    title: string,
    fundRequirements: FundRequirement[],
    applicationRemarks?: string,
};

type FundData = {
    code: string,
    type: number,
    title: string,
    fund_requirements: FundRequirement[],
    application_remarks?: string,
};

type FundRequirement = {
    title: string,
    requirements: Requirement[]
}

type Requirement = {
    content: string,
}

export interface Specialist {
    profileImageUrl: string,
    subProfileImageUrl: string,
    uuid: string,
    role: string,
    label: string,
    email: string,
    name: string,
    officeName: string,
    phone?: string,
    address: string,
    schedules?: Schedule[],
}

type SpecialistData = {
    profile_image_url: string,
    sub_profile_image_url: string,
    uuid: string,
    role: string,
    label: string,
    email: string,
    name: string,
    office_name: string,
    phone?: string,
    address: string,
    schedules?: Schedule[],
}

export type Schedule = {
    uuid?: string,
    number: number,
    content: string,
    expired: boolean
}

export type Consultation = {
    uuid: string,
    company: Company,
    daidoJoins: number,
    funds?: Fund[],
    isDeadline: boolean,
    joinUrl?: string,
    specialist?: Specialist,
    specialists?: Specialist[],
    password: string,
    schedule: string,
    scheduleDatetime: string,
    chukidanSchedules?: ChukidanSchedule[],
    status: `${ConsultationStatuses}`,
    topics?: Topic[],
    remarks?: string,
    personInCharge?: string,
    cancellationReason?: string,
    cancellationDate?: string,
    createdAt?: string,
    type?: string,
}

type ConsultationData = {
    uuid: string,
    company: CompanyData,
    daido_joins: number,
    funds?: FundData[],
    is_deadline: boolean,
    join_url: string,
    specialist?: SpecialistData,
    specialists?: SpecialistData[],
    password: string,
    schedule: string,
    schedule_datetime: string,
    chukidan_schedules?: ChukidanSchedule[],
    status: `${ConsultationStatuses}`,
    topics?: Topic[],
    remarks?: string,
    person_in_charge?: string,
    cancellation_reason?: string,
    cancellation_date?: string,
    created_at?: string,
    type?: string,
}

type Topic = {
    code: string,
    name: string,
}

type ChukidanSchedule = {
    uuid: string,
    number: number,
    content: string,
    expired: boolean
}

export type CancellationConsultationRequest = {
    applicationUuid: string,
    reason: string,
}

export type ScheduleConsultationRequest = {
    uuid: string,
    specialist_uuid: string,
    number: number,
    daido_joins: boolean,
}

export type SpecialistProfileData = {
    profileImageUrl: string,
    subProfileImageUrl: string,
    uuid: string,
    role: string,
    label: string,
    name: string,
    nameKana: string,
    prefecture: string,
    address: string,
    buildingName: string,
    prefectures: string[],
    otherAreas: string,
    otherAddress?: string,
    officePR?: string,
    officeHistory?: string,
    qualifications?: string,
    interview?: string,
    fields: string[],
    industries: string[],
    employeeCountRanges: string[],
    phone?: string,
    email?: string,
    twitterUrl?: string,
    facebookUrl?: string,
    homepageUrl?: string,
    businessManualUrl?: string,
    specialistCertificationImages?: string[]
}

export const fetchConsultationDetail = createAsyncThunk('daido/consultation/detail',
    async ({ customerUuid, applicationUuid }: GetConsultationDetailRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getConsultationDetail({ customerUuid, applicationUuid });
            const { data = {}, success = false } = response.data;

            if (success) {
                dispatch(setConsultationDetail(data as ConsultationData));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(false);
        }
    });

export const asyncCancellationConsultation = createAsyncThunk('daido/consulation/cancel',
    async (params: CancellationConsultationRequest, { rejectWithValue }) => {
        try {
            const response = await cancellationConsultation(params);
            const { success = false } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });


/**
 * POST Application Consultation / Meeting Schedule
 */
export const setConsultationSchedule = createAsyncThunk(
    '/company/applications/:uuid/schedule',
    async (data: ScheduleConsultationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await scheduleConsultationApi(data);

            if (response.data.success === true) {
                let details = response.data.data;
                details.joinUrl = details.join_url;
                details.scheduleDatetime = details.schedule_datetime;
                details.daidoJoins = details.daido_joins;
                if (details.specialist) {
                    details.specialist.officeName = details.specialist.office_name || '';
                    details.specialist.profileImageUrl = details.specialist.profile_image_url || '';
                    details.specialist.subProfileImageUrl = details.specialist.sub_profile_image_url || '';
                }
                dispatch(setSchedule(response.data.data));

                return details as Consultation;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * GET Specialist Profile
 **/
export const getSpecialistProfile = createAsyncThunk(
    '/company/applications/:uuid/specialist/:specialist',
    async (data: { uuid: string, specialistUuid: string }, { rejectWithValue, dispatch }) => {
        try {
            const response = await getSpecialistProfileApi(data.uuid, data.specialistUuid);

            if (response.data.success === true) {
                dispatch(setSpecialistProfile(response.data.data));
                return data;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const consultationSlice = createSlice({
    name: 'consultation',
    initialState: {
        consultationDetail: {
            loading: false,
            success: false,
            error: false,
            consultation: {},
        },
        cancellationConsultation: {
            loading: false,
            success: false,
            error: false,
            messages: '',
        },
        specialistProfile: {
            loading: false,
            success: false,
            error: false,
            profile: {},
        },
        scheduleConsultation: {
            loading: false,
            success: false,
            error: false,
        },
    } as ConsultationState,
    reducers: {
        setConsultationDetail: (state: ConsultationState, { payload }: { payload: ConsultationData }) => {
            state.consultationDetail.consultation = {
                uuid: payload.uuid,
                company: {
                    name: payload.company.name,
                    address: payload.company.address,
                    industry: payload.company.industry,
                    laborRegulationsCompliant: payload.company.labor_regulations_compliant,
                    allowSharoushi: payload.company.allow_sharoushi,
                    allowShindanshi: payload.company.allow_shindanshi,
                    hasCompanyInsurance: payload.company.has_company_insurance,
                    personInCharge: payload?.company?.person_in_charge,
                },
                daidoJoins: payload.daido_joins,
                funds: payload?.funds?.map(fund => ({
                    code: fund.code,
                    type: fund.type,
                    title: fund.title,
                    fundRequirements: fund.fund_requirements,
                    applicationRemarks: fund?.application_remarks,
                })),
                isDeadline: payload.is_deadline,
                joinUrl: payload.join_url,
                specialist: payload?.specialist && {
                    profileImageUrl: payload?.specialist?.profile_image_url,
                    subProfileImageUrl: payload?.specialist?.sub_profile_image_url,
                    uuid: payload?.specialist?.uuid,
                    role: payload?.specialist?.role,
                    label: payload?.specialist?.label,
                    email: payload?.specialist?.email,
                    name: payload?.specialist?.name,
                    officeName: payload?.specialist?.office_name,
                    phone: payload?.specialist?.phone,
                    address: payload?.specialist?.address,
                },
                specialists: payload?.specialists?.map(specialist => ({
                    profileImageUrl: specialist?.profile_image_url,
                    subProfileImageUrl: specialist?.sub_profile_image_url,
                    uuid: specialist?.uuid,
                    role: specialist?.role,
                    label: specialist?.label,
                    email: specialist?.email,
                    name: specialist?.name,
                    officeName: specialist?.office_name,
                    address: specialist?.address,
                    schedules: specialist?.schedules,
                })),
                chukidanSchedules: payload.chukidan_schedules,
                password: payload.password,
                schedule: payload.schedule,
                scheduleDatetime: payload.schedule_datetime,
                status: payload.status,
                cancellationReason: payload?.cancellation_reason,
                cancellationDate: payload?.cancellation_date,
                createdAt: payload?.created_at,
                personInCharge: payload?.person_in_charge,
                remarks: payload?.remarks,
                topics: payload?.topics,
                type: payload?.type,
            };
        },
        setSpecialistProfile: (state, { payload }) => {
            state.specialistProfile.profile = {
                address: payload.address,
                buildingName: payload.building_name,
                businessManualUrl: payload.business_manual_url,
                email: payload.email,
                employeeCountRanges: payload.employee_count_ranges,
                facebookUrl: payload.facebook_url,
                fields: payload.fields,
                homepageUrl: payload.homepage_url,
                industries: payload.industries,
                interview: payload.interview,
                label: payload.label,
                name: payload.name,
                nameKana: payload.name_kana,
                officeHistory: payload.office_history,
                officePR: payload.office_pr,
                otherAddress: payload.other_address,
                otherAreas: payload.other_areas,
                phone: payload.phone,
                prefecture: payload.prefecture,
                prefectures: payload.prefectures,
                profileImageUrl: payload.profile_image_url,
                qualifications: payload.qualifications,
                role: payload.role,
                specialistCertificationImages: payload.specialist_certification_images,
                subProfileImageUrl: payload.sub_profile_image_url,
                twitterUrl: payload.twitter_url,
                uuid: payload.uuid,
            };
        },
        resetSpecialistProfileState: (state: ConsultationState) => {
            state.specialistProfile = {
                loading: false,
                success: false,
                error: false,
                profile: {} as SpecialistProfileData,
            };
        },
        resetCancellationState: (state: ConsultationState) => {
            state.cancellationConsultation = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        setSchedule: (state, { payload }) => {
            state.consultationDetail.consultation.scheduleDatetime = payload.schedule_datetime;
            state.consultationDetail.consultation.joinUrl = payload.join_url;
            state.consultationDetail.consultation.password = payload.password;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConsultationDetail.pending, (state: ConsultationState) => {
                state.consultationDetail.loading = true;
            })
            .addCase(fetchConsultationDetail.fulfilled, (state: ConsultationState) => {
                state.consultationDetail.loading = false;
                state.consultationDetail.success = true;
                state.consultationDetail.error = false;
            })
            .addCase(fetchConsultationDetail.rejected, (state: ConsultationState) => {
                state.consultationDetail.loading = false;
                state.consultationDetail.success = false;
                state.consultationDetail.error = true;
            })
            .addCase(asyncCancellationConsultation.pending, (state: ConsultationState) => {
                state.cancellationConsultation.loading = true;
            })
            .addCase(asyncCancellationConsultation.rejected, (state: ConsultationState, { payload }) => {
                state.cancellationConsultation.loading = false;
                state.cancellationConsultation.success = false;
                state.cancellationConsultation.error = true;
                state.cancellationConsultation.messages = payload as string | CancellationValidateMessage;
            })
            .addCase(asyncCancellationConsultation.fulfilled, (state: ConsultationState) => {
                state.cancellationConsultation.loading = false;
                state.cancellationConsultation.success = true;
                state.cancellationConsultation.error = false;
            })
            .addCase(getSpecialistProfile.pending, (state: ConsultationState) => {
                state.specialistProfile.loading = true;
                state.specialistProfile.profile = {} as SpecialistProfileData;
            })
            .addCase(getSpecialistProfile.rejected, (state: ConsultationState) => {
                state.specialistProfile.loading = false;
                state.specialistProfile.success = false;
                state.specialistProfile.error = true;
            })
            .addCase(getSpecialistProfile.fulfilled, (state: ConsultationState) => {
                state.specialistProfile.loading = false;
                state.specialistProfile.success = true;
                state.specialistProfile.error = false;
            })
            .addCase(setConsultationSchedule.pending, (state: ConsultationState) => {
                state.scheduleConsultation.loading = true;
            })
            .addCase(setConsultationSchedule.rejected, (state: ConsultationState) => {
                state.scheduleConsultation.loading = false;
                state.scheduleConsultation.success = false;
                state.scheduleConsultation.error = true;
            })
            .addCase(setConsultationSchedule.fulfilled, (state: ConsultationState) => {
                state.scheduleConsultation.loading = false;
                state.scheduleConsultation.success = true;
                state.scheduleConsultation.error = false;
            });
    },
});

export const consultationSelector = (state: RootState) => state.consultation;
export const {
    setConsultationDetail,
    resetCancellationState,
    setSchedule,
    setSpecialistProfile,
    resetSpecialistProfileState,
} = consultationSlice.actions;
