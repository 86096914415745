import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { FetchKotekiMeetingEmployeeDetailRequest, FetchWorkRegulationConsultationListRequest } from 'types';
import { getPublicSupportConsultationList, getWorkRegulationConsultationList } from 'api/kouteki';
import { getErrorMessage } from 'api';

type Schedule = {
    content: string;
    number: number;
};

type Status = {
    content: string;
    color: string;
};

export type Label = {
    content: string;
    color: string;
};

export type Pagination = {
    count: number;
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

type SpecialistSelected = {
    profileImageUrl: string;
    subProfileImageUrl: string;
    uuid: string;
    role: string;
    label: string;
    email: string;
    name: string;
    officeName: string;
    phone: string;
};

export type Application = {
    uuid: string;
    corpUserUuid: string;
    fundsTitle?: string[];
    isDeadline: boolean;
    label: Label;
    schedule: string;
    specialist: Specialist[] | null;
    status: number;
    specialistSelected?: SpecialistSelected;
};

type Consultation = {
    uuid: string;
    is_deadline: boolean;
    corp_user_id: string;
    funds_title?: string[];
    label: Label;
    schedule: string;
    specialists?: SpecialistData[];
    status: number;
    specialist_selected?: SpecialistSelectedData;
};

type SpecialistData = {
    uuid: string;
    role: string;
    email: string;
    name: string;
    address: string;
    label: string;
    office_name: string;
    profile_image_url: string;
    schedules: Schedule[];
    sub_profile_image_url: string;
};

export type Specialist = {
    uuid: string;
    role: string;
    email: string;
    name: string;
    address: string;
    label: string;
    officeName: string;
    profileImageUrl: string;
    schedules: Schedule[];
    subProfileImageUrl: string;
};

type SpecialistSelectedData = {
    profile_image_url: string;
    sub_profile_image_url: string;
    uuid: string;
    role: string;
    label: string;
    email: string;
    name: string;
    office_name: string;
    phone: string;
};

type WorkRegulationData = {
    title: string;
    request_date: string;
    interview_date: string;
    status: Status;
    corp_user_id: string;
    uuid: string;
};

export const getKoutekiMeeting = createAsyncThunk(
    'daido/employee/kouteki/meeting',
    async (params: FetchKotekiMeetingEmployeeDetailRequest, { dispatch, rejectWithValue }) => {
        try {
            if (params.status === undefined || params.status === -1) {
                delete params.status;
            }
            let response = await getPublicSupportConsultationList(params);
            const { data = [], pagination = {}, success } = response.data;
            if (success) {
                dispatch(setKoutekiMeetingsData({ data, pagination }));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getKisokuMeeting = createAsyncThunk(
    'daido/employee/kisoku/meeting',
    async (params: FetchWorkRegulationConsultationListRequest, { dispatch, rejectWithValue }) => {
        try {
            if (params.status === -1 || params.status === undefined) {
                delete params.status;
            }
            let response = await getWorkRegulationConsultationList(params);
            const { data = [], success } = response.data;
            if (success) {
                dispatch(setKisokuMeetingsData(data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const userDetailsSlice = createSlice({
    name: 'employee-kouteki-meetting',
    initialState: {
        loading: false,
        success: false,
        consultations: [],
        workRegulations: [],
        pagination: {} as Pagination
    },
    reducers: {
        setKoutekiMeetingsData: (state, { payload }) => {
            state.consultations = payload.data.map((consultation: Consultation) => {
                return {
                    uuid: consultation.uuid,
                    corpUserUuid: consultation.corp_user_id,
                    fundsTitle: consultation.funds_title,
                    isDeadline: consultation.is_deadline,
                    label: consultation.label,
                    schedule: consultation.schedule,
                    status: consultation.status,
                    specialistSelected: consultation?.specialist_selected,
                    specialist:
                        consultation.specialists &&
                        consultation.specialists.map((specialist: SpecialistData) => {
                            return {
                                uuid: specialist.uuid,
                                role: specialist.role,
                                email: specialist.email,
                                name: specialist.name,
                                address: specialist.address,
                                label: specialist.label,
                                officeName: specialist.office_name,
                                profileImageUrl: specialist.profile_image_url,
                                schedules: specialist.schedules,
                                subProfileImageUrl: specialist.sub_profile_image_url
                            };
                        })
                };
            });
            state.pagination = payload.pagination;
            state.pagination.perPage = payload.pagination.per_page;
            state.pagination.currentPage = payload.pagination.current_page;
            state.pagination.lastPage = payload.pagination.last_page;
        },
        resetKoutekiMeetingsData: (state) => {
            state.loading = true;
            state.consultations = [];
            state.pagination = {} as Pagination;
        },
        setKisokuMeetingsData: (state, { payload }) => {
            state.workRegulations = payload.map((workRegulation: WorkRegulationData) => ({
                title: workRegulation.title,
                requestDate: workRegulation.request_date,
                interviewDate: workRegulation.interview_date,
                status: workRegulation.status,
                corpUserId: workRegulation.corp_user_id,
                uuid: workRegulation.uuid
            }));
        },
        resetKisokuMeetingsData: (state) => {
            state.loading = true;
            state.workRegulations = [];
            state.pagination = {} as Pagination;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getKoutekiMeeting.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getKoutekiMeeting.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getKoutekiMeeting.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.consultations = [];
        });
        builder.addCase(getKisokuMeeting.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getKisokuMeeting.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getKisokuMeeting.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.workRegulations = [];
        });
    }
});

export const { setKoutekiMeetingsData, resetKoutekiMeetingsData, setKisokuMeetingsData, resetKisokuMeetingsData } = userDetailsSlice.actions;
export const userDetailsSelector = (state: RootState) => state.userDetails;
