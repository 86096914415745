import { ChangeMeetingScheduleRequest, GetCalendarScheduleRequest } from 'app/slice/companySlice';
import { getAxiosClientWithToken } from './index';

export const changePasswordUser = (
    current_password: string,
    new_password: string,
    new_password_confirmation: string
) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/daido/update-password`, {
        current_password,
        new_password,
        new_password_confirmation
    });
};

export const unsubcribeUser = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/company/users/${uuid}`);
};

export const getCalendarScheduleApi = (request: GetCalendarScheduleRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/calendar`, { params: request });
};

export const changeMeetingScheduleApi = (request: ChangeMeetingScheduleRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/daido/meeting/${request.uuid}/change`, request);
};
