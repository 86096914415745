import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeMeetingScheduleApi, changePasswordUser, getCalendarScheduleApi, unsubcribeUser } from 'api/company';
import { RootState } from 'app/store';
import { CalendarType } from 'enums';

export type COMPANY_STATUS = 'success' | 'failed' | 'pending';

type CompanyState = {
    loading: boolean;
    success: boolean;
    type: string;
    status: COMPANY_STATUS;
    message: string;
    calendar: CalendarSchedule;
};

type unsubscribeRequest = {
    uuid: string;
};

export type ChangePasswordRequest = {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
};

export type GetCalendarScheduleRequest = {
    month: string;
    type: number;
    taken?: number;
};

type CalendarSchedule = {
    availableSchedules: AvailableSchedules[];
    holidays: {
        start: string,
        end: string,
    }[];
    selectableFrom: string;
    selectableUntil: string;
};

type AvailableSchedules = {
    date: string;
    time: string[];
};

export type ChangeMeetingScheduleRequest = {
    new_schedule: string;
    type: CalendarType | null;
    uuid: string;
};

export const GET_CALENDAR_SCHEDULES = 'GET_CALENDAR_SCHEDULES';

/**
 * Get all holidays and schedules.
 **/
export const getCalendarSchedule = createAsyncThunk(
    'daido/calendar',
    async (data: GetCalendarScheduleRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getCalendarScheduleApi(data);
            if (response.data.success === true) {
                dispatch(setCalendarSchedule(response.data));
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors.
            return rejectWithValue(false);
        }
    }
);

/**
 * Change meeting schedule.
 **/
export const changeMeetingSchedule = createAsyncThunk(
    'daido/meeting/application/change',
    async (data: ChangeMeetingScheduleRequest, { rejectWithValue }) => {
        try {
            const response = await changeMeetingScheduleApi(data);
            if (response.data.success === true) {
                return true;
            }
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors.
            return rejectWithValue(false);
        }
    }
);

/**
 * changePassword user
 **/
export const changePassword = createAsyncThunk(
    'company/user/password',
    async (data: ChangePasswordRequest, { getState, rejectWithValue }) => {
        const { current_password, new_password, new_password_confirmation } = data;
        // @ts-ignore
        const { loading } = getState().company;
        if (!loading) {
            return;
        }
        try {
            const response = await changePasswordUser(current_password, new_password, new_password_confirmation);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    }
);

/**
 * unsubscribe user
 **/
export const unsubscribe = createAsyncThunk(
    'company/user',
    async (data: unsubscribeRequest, { getState, rejectWithValue }) => {
        const { uuid } = data;
        // @ts-ignore
        const { loading } = getState().company;
        if (!loading) {
            return;
        }
        try {
            const response = await unsubcribeUser(uuid);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    }
);

const initialState: CompanyState = {
    loading: false,
    success: false,
    type: '',
    status: 'pending',
    message: '',
    calendar: {
        availableSchedules: [] as AvailableSchedules[],
        holidays: [],
        selectableFrom: '',
        selectableUntil: '',
    },
};

export const companySlice = createSlice({
    name: 'company',
    initialState: initialState,
    reducers: {
        reset: (state: CompanyState) => {
            state.success = false;
            state.loading = false;
            state.status = 'pending';
            state.message = '';
        },
        setCalendarSchedule: (state, { payload }) => {
            state.calendar.availableSchedules = payload.data.available_schedules;
            state.calendar.holidays = payload.data.holidays;
            state.calendar.selectableFrom = payload.data.selectable_from;
            state.calendar.selectableUntil = payload.data.selectable_until;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(unsubscribe.pending, (state) => {
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase(unsubscribe.fulfilled, (state) => {
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase(unsubscribe.rejected, (state) => {
            state.loading = false;
            state.status = 'failed';
        });
        builder.addCase(changePassword.pending, (state) => {
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase(changePassword.fulfilled, (state) => {
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase(changePassword.rejected, (state) => {
            state.loading = false;
            state.status = 'failed';
        });
        builder.addCase(getCalendarSchedule.pending, (state) => {
            state.type = GET_CALENDAR_SCHEDULES;
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase(getCalendarSchedule.fulfilled, (state) => {
            state.type = GET_CALENDAR_SCHEDULES;
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase(getCalendarSchedule.rejected, (state) => {
            state.type = GET_CALENDAR_SCHEDULES;
            state.loading = false;
            state.status = 'failed';
        });
        builder.addCase(changeMeetingSchedule.pending, (state) => {
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase(changeMeetingSchedule.fulfilled, (state) => {
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase(changeMeetingSchedule.rejected, (state) => {
            state.loading = false;
            state.status = 'failed';
        });
    }
});

export const { reset, setCalendarSchedule } = companySlice.actions;
export const companySelector = (state: RootState) => state.company;
