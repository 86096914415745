import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getKisokuMeetingDetails, selectKisokuMeetingDetails } from 'app/slice/kisokuMeetingDetailsSlice';
import KisokuMeetingDetailsHeader from 'components/common/meeting_detail_page/KisokuMeetingDetailsHeader';
import KisokuMeetingDetailsBody from 'components/common/meeting_detail_page/KisokuMeetingDetailsBody';

const KisokuMeetingDetailsCorp = () => {
    const { data } = useAppSelector(selectKisokuMeetingDetails);
    const { customerUuid, applicationUuid } = useParams();
    const dispatch = useAppDispatch();

    // Get kisoku meeting details based on customer uuid and application uuid.
    useEffect(() => {
        if (customerUuid && applicationUuid) {
            dispatch(getKisokuMeetingDetails({ customerUuid, applicationUuid }));
        }
    }, [customerUuid, applicationUuid, dispatch]);

    return (
        <>
            <KisokuMeetingDetailsHeader applicationStatus={data.status} />
            <KisokuMeetingDetailsBody data={data} />
        </>
    );
};

export default KisokuMeetingDetailsCorp;
