import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { useState } from 'react';
import NewScheduleModal from './NewScheduleModal';
import { useAppDispatch } from 'app/hooks';
import { changeMeetingSchedule } from 'app/slice/companySlice';
import { CalendarType } from 'enums';

type Props = {
    isVisible: boolean;
    title: string;
    uuid: string;
    oldSchedule: string;
    newScheduleLabel: string;
    newScheduleRequest: string;
    fundType?: number;
    onOpenChangeScheduleModal: () => void;
    onClose: () => void;
};
const ChangeScheduleModal = (props: Props) => {
    const {
        isVisible,
        title,
        uuid,
        oldSchedule,
        newScheduleLabel,
        newScheduleRequest,
        fundType,
        onOpenChangeScheduleModal,
        onClose
    } = props;
    const dispatch = useAppDispatch();
    const [isNewScheduleModalVisible, setIsNewScheduleModalVisible] = useState(false);
    const onCloseNewScheduleConfirmationModal = () => setIsNewScheduleModalVisible(false);

    const openNewScheduleModal = () => {
        onClose();
        setIsNewScheduleModalVisible(true);
    };

    const goBackToChangeScheduleModal = () => {
        onClose();
        onOpenChangeScheduleModal();
    };

    const updateMeetingSchedule = () => {
        const changeSchedulePayload = {
            uuid: uuid,
            new_schedule: newScheduleRequest,
            type: fundType === 4 ? CalendarType.GRANTS : fundType === 3 ? CalendarType.SUBSIDY : CalendarType.POLICY
        };

        if (changeSchedulePayload?.type) {
            dispatch(changeMeetingSchedule(changeSchedulePayload));
        }

        openNewScheduleModal();
    };

    return (
        <>
            <Modal
                className={'schedule-confirmation-modal'}
                visible={isVisible}
                footer={null}
                onCancel={onClose}
                maskClosable={false}
            >
                <div className='title'>{title}</div>

                <div className='details-div-top'>
                    <Row className='details-row'>
                        <Col span={8} className='details-label'>
                            変更前
                        </Col>

                        <Col span={16} className='details-value'>
                            {oldSchedule}
                        </Col>
                    </Row>
                </div>

                <div className='details-div'>
                    <Row className='details-row'>
                        <Col span={8} className='details-label'>
                            変更後
                        </Col>

                        <Col span={16} className='details-date'>
                            {newScheduleLabel}
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row>
                        <Col md={9}>
                            <Button className='btn-back' onClick={goBackToChangeScheduleModal}>
                                <h3 className='btn-back-text'>
                                    <LeftOutlined className='btn-icon' />
                                    戻る
                                </h3>
                            </Button>
                        </Col>
                        <Col md={9} className='col-next'>
                            <Button
                                className={`btn-next round-button bg-lb-6 fc-white new-schedule-primary-btn m-auto`}
                                onClick={updateMeetingSchedule}
                            >
                                <h3 className='btn-next-text'>変更する</h3>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>

            {/* Modal for new meeting schedule */}
            <NewScheduleModal
                isVisible={isNewScheduleModalVisible}
                title='面談予約の日程変更が完了しました'
                header='下記の日程で面談予約を受け付けました。'
                subHeader='面談URLは更新されましたので、面談詳細ページにてご確認ください。'
                newScheduleLabel={newScheduleLabel}
                fundType={fundType}
                onClose={onCloseNewScheduleConfirmationModal}
            />
        </>
    );
};

export default ChangeScheduleModal;
