import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMeetingDetailsApi } from "api/meetingDetails";
import { RootState } from "app/store";

export const GET_KISOKU_MEETING_DETAILS = 'GET_KISOKU_MEETING_DETAILS';

export type GetMeetingDetailsRequest = {
    customerUuid: string;
    applicationUuid: string;
};

// Kisoku Meetings Details State
type KisokuMeetingDetailsState = {
    success: boolean | null,
    loading: boolean,
    data: KisokuMeetingDetails,
    type: string
}

export type KisokuMeetingDetails = {
    uuid: string;
    person_in_charge: string | null;
    date_created: string;
    status: string;
    read_status: number;
    type: string;
    specialist_schedule_label: string;
    company: Company;
    topics: {
        code: string;
        name: string;
    }[];
    meeting: Meeting;
    cancellation_reason: string | null;
    cancellation_date: string | null;
};

type Company = {
    name: string;
    address: string;
    industry: string;
    labor_regulations_compliant: number;
    allow_sharoushi: number;
    allow_shindanshi: number;
    insurances: Insurances[];
    person_in_charge: string;
    prefecture: string;
    employee_count_ranges: string;
    parttime_employee_count: number;
};

type Meeting = {
    zoom_id: string;
    join_url: string;
    daido_joins: number;
    password: string;
    emergency_contact_number: string | null;
    meeting_remarks: string;
    schedule: string;
    schedule_datetime: string;
    unimplemented_comments: string;
};

type Insurances = {
    code: string;
    name: string;
    created_at: string | null;
    updated_at: string | null;
    pivot: {
        company_dd: number;
        insurance_plan_dd: number;
    };
};

/**
 * GET Kisoku meeting details by customerUuid and applicationUuid
 **/
export const getKisokuMeetingDetails = createAsyncThunk('/company/applications/details', async (data: GetMeetingDetailsRequest, { dispatch, rejectWithValue }) => {
    try {
        const response = await getMeetingDetailsApi(data);
        if (response.data.success === true) {
            dispatch(setKisokuMeetingDetails(response.data));
            return true;
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(false);
    }
},
);

/**
 * Create Kisoku Meeting Details Slice
 */
export const kisokuMeetingDetailsSlice = createSlice({
    name: 'application',
    initialState: {
        success: false,
        loading: false,
        data: {} as KisokuMeetingDetails,
        type: '',
    } as KisokuMeetingDetailsState,
    reducers: {
        reset: (state: KisokuMeetingDetailsState) => {
            state.success = false;
            state.loading = true;
            state.data = {} as KisokuMeetingDetails;
            state.type = '';
        },
        setKisokuMeetingDetails: (state, { payload }) => {
            state.data = payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getKisokuMeetingDetails.pending, (state: KisokuMeetingDetailsState) => {
            state.type = GET_KISOKU_MEETING_DETAILS;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(getKisokuMeetingDetails.rejected, (state: KisokuMeetingDetailsState) => {
            state.type = GET_KISOKU_MEETING_DETAILS;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getKisokuMeetingDetails.fulfilled, (state: KisokuMeetingDetailsState) => {
            state.type = GET_KISOKU_MEETING_DETAILS;
            state.loading = false;
            state.success = false;
        });
    },
});

export const { setKisokuMeetingDetails } = kisokuMeetingDetailsSlice.actions;
export const selectKisokuMeetingDetails = (state: RootState) => state.kisokuMeetingDetails;