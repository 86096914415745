import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import ScheduleConfirmationModal from './ScheduleConfirmationModal';
import CalendarSchedule from './CalendarSchedule';
import { formatDateWithWeekday } from 'lib/utils';

type Props = {
    isVisible: boolean;
    title: string;
    reasonText: string;
    uuid: string;
    oldSchedule: string;
    fundType?: number;
    onOpenChangeScheduleModal: () => void;
    onClose: () => void;
};
const ChangeScheduleModal = (props: Props) => {
    const { isVisible, title, reasonText, uuid, oldSchedule, fundType, onOpenChangeScheduleModal, onClose } = props;
    const [isScheduleConfirmationModalVisible, setIsScheduleConfirmationModalVisible] = useState(false);
    const [isDisabledConfirmationButton, setDisabledConfirmationButton] = useState(false);
    const onCloseScheduleConfirmationModal = () => setIsScheduleConfirmationModalVisible(false);

    //New fields for handling schedule date and time changed.
    const [scheduleDate, setScheduleDate] = useState<string>('--');
    const [scheduleTime, setScheduleTime] = useState<string>('--');

    const formattedDate = scheduleDate.replace('年', '-').replace('月', '-').replace('日', '');
    const formattedDateWithWeekday = formatDateWithWeekday(formattedDate);
    const [startTime] = scheduleTime.split('~');

    const formattedLabelSchedule = formattedDateWithWeekday + ' ' + scheduleTime;
    const formattedSchedule = `${formattedDate} ${startTime}:00`;

    //Disable confirmation button if no schedule time selected.
    useEffect(() => {
        if (scheduleTime === '--') {
            setDisabledConfirmationButton(true);
        } else {
            setDisabledConfirmationButton(false);
        }
    }, [scheduleTime, scheduleDate, setScheduleDate, setScheduleTime]);

    const openScheduleConfirmationModal = () => {
        onClose();
        setIsScheduleConfirmationModalVisible(true);
    };

    const onCloseChangeScheduleModal = () => {
        onClose();
    };

    return (
        <>
            <Modal
                className='change-schedule-modal'
                visible={isVisible}
                footer={null}
                onCancel={onClose}
                maskClosable={false}
                centered
            >
                <div className='title'>{title}</div>

                <div className='details-div-top'>
                    <Row className='details-row'>
                        <Col span={6} className='details-label'>
                            日程変更について
                        </Col>

                        <Col span={18} className='details-value'>
                            {reasonText}
                        </Col>
                    </Row>
                </div>

                <div className='details-div'>
                    <Row className='details-row'>
                        <Col span={6} className='details-label'>
                            予約中の面談日
                        </Col>

                        <Col span={18} className='details-value'>
                            {oldSchedule}
                        </Col>
                    </Row>
                </div>

                <div className='schedule-calender'>
                    <Row justify='start' className='mb-3' gutter={[16, 0]}>
                        <Col span={24} md={24}>
                            <div>
                                <CalendarSchedule
                                    setScheduleDate={setScheduleDate}
                                    setScheduleTime={setScheduleTime}
                                    scheduleDate={scheduleDate}
                                    scheduleTime={scheduleTime}
                                    fundType={fundType}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row>
                        <Col md={9}>
                            <Button className='btn-close' onClick={onCloseChangeScheduleModal}>
                                <h3 className='btn-close-text'>
                                    <CloseOutlined className='btn-icon' />
                                    変更しない
                                </h3>
                            </Button>
                        </Col>
                        <Col md={9}>
                            <Button
                                disabled={isDisabledConfirmationButton}
                                className={`btn-next round-button bg-lb-6 fc-white change-sched-primary-btn m-auto`}
                                onClick={openScheduleConfirmationModal}
                            >
                                <h3 className='btn-next-text'>確認画面に進む</h3>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>

            {/* Modal to confirm change schedule of meeting */}
            <ScheduleConfirmationModal
                isVisible={isScheduleConfirmationModalVisible}
                title='下記の日程に変更しますか？'
                uuid={uuid}
                oldSchedule={oldSchedule}
                newScheduleLabel={formattedLabelSchedule}
                newScheduleRequest={formattedSchedule}
                fundType={fundType}
                onClose={onCloseScheduleConfirmationModal}
                onOpenChangeScheduleModal={onOpenChangeScheduleModal}
            />
        </>
    );
};

export default ChangeScheduleModal;
