import React, { useEffect, useState } from 'react';
import { CopyOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, message, Row } from 'antd';
import { ApplicationStatuses, KanriDaidoRoleName } from 'enums';
import { authSelector } from 'app/slice/authSlice';
import { useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate, scrollToTop } from 'lib/utils';
import { KisokuMeetingDetails } from 'app/slice/kisokuMeetingDetailsSlice';
import ChangeScheduleModal from 'components/common/ChangeScheduleModal';
import KisokuCancel from 'components/dashboard/cancelModal/kisoku';

type Props = {
    data: KisokuMeetingDetails;
};

const KisokuMeetingDetailsBody = (props: Props) => {
    const { data } = props;
    const { roleName } = useAppSelector(authSelector);
    const { customerUuid, applicationUuid } = useParams();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [showTopics, setShowTopics] = useState(false);
    const dateCreated = data?.date_created;
    const formattedDateCreated = formatDate(dateCreated);
    const cancellationDate = data?.cancellation_date;
    const formattedCancellationDate = formatDate(cancellationDate);
    const [isChangeScheduleModalVisible, setIsChangeScheduleModalVisible] = useState(false);

    // Scroll to top on navigate
    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const toggleShowTopics = () => {
        setShowTopics(!showTopics);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(data?.meeting?.join_url || '').then(() => messageApi.info('URLをコピーしました'));
    };

    const openChangeScheduleModal = () => {
        setIsChangeScheduleModalVisible(true);
    };

    const onCloseChangeScheduleModal = () => setIsChangeScheduleModalVisible(false);

    return (
        <>
            {contextHolder}
            <div className={'d-flex row justify-content-sm-end mt-4'}>
                <span>面談依頼日：{formattedDateCreated}</span>
            </div>

            <div className={'d-flex flex-column'}>
                <div className='mt-2 meeting-details-div-top'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            面談対象規程
                        </Col>

                        <Col span={18} className='meeting-details-grant-title'>
                            {data?.type}
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            面談日時
                        </Col>

                        <Col span={18} className='meeting-details-date'>
                            {data?.meeting?.schedule_datetime}
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            ミーティングURL
                        </Col>

                        <Col span={18} className='meeting-details-value'>
                            <Row>
                                {data?.status === ApplicationStatuses.INTERVIEWED ? (
                                    <Col span={24}>本面談は終了いたしました</Col>
                                ) : data?.status === ApplicationStatuses.CANCELLED ? (
                                    <Col span={24}>本面談はキャンセルとなりました</Col>
                                ) : data?.status === ApplicationStatuses.NOT_IMPLEMENTED ? (
                                    <Col span={24}>
                                        ご予約のお時間にZoomへの入室がなかったため、本面談はキャンセルとなりました
                                    </Col>
                                ) : (
                                    data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED && (
                                        <>
                                            <Col span={18}>
                                                <a className='link' href={data?.meeting?.join_url || ''} target='_blank' rel='noreferrer'>
                                                    {data?.meeting?.join_url}
                                                </a>
                                            </Col>
                                            <Col span={4} className='copy-clipboard' onClick={copyToClipboard}>
                                                <CopyOutlined /> URLをコピー
                                            </Col>
                                        </>
                                    )
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            入室パスワード
                        </Col>
                        {data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED ? (
                            <Col span={18} className='meeting-details-value'>
                                {data?.meeting?.password}
                            </Col>
                        ) : (
                            <Col span={18} className='meeting-details-value'>
                                ー
                            </Col>
                        )}
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            面談のご案内
                        </Col>
                        <Col span={18} className='meeting-details-value'>
                            <div className={'d-flex flex-column'}>
                                <div className={'d-flex'}>
                                    <div>※</div>
                                    <div>本面談は「社会保険労務士」または「中小企業診断士」がご対応いたします。</div>
                                </div>
                                <div className={'d-flex'}>
                                    <div>※</div>
                                    <div>面談ルームへの入室は予約時刻の５分前から可能です。</div>
                                </div>
                                <div className={'d-flex'}>
                                    <div>※</div>
                                    <div>
                                        面談時間は予約時刻から３０分間です。３０分を過ぎた場合は予告なく面談終了となる可能性がござい
                                        ます。予めご了承ください。
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-div'>
                    <Row className='meeting-details-row'>
                        <Col span={6} className='meeting-details-label'>
                            大同生命担当者
                        </Col>

                        <Col span={18} className='meeting-details-value'>
                            {data?.meeting?.daido_joins ? '同席を希望する' : '同席を希望しない'}
                        </Col>
                    </Row>
                </div>

                <div className='meeting-details-background mt-4 mb-4'>
                    <div className={'d-flex row py-3 meeting-details-border'}>
                        <div className={'mx-3 w-25 meeting-details-discussion-title'}>特にご相談したいこと</div>
                        <div className={'d-flex row mx-3 w-50'}>
                            <div className={'d-flex'}>上記公的資金の診断の際に選択・チェックされた項目です。</div>
                        </div>
                        <div className={'d-flex row align-self-center w-25 justify-content-sm-end mx-4'}>
                            <Button
                                shape={'round'}
                                onClick={toggleShowTopics}
                                className='round-button meeting-details-accordion-button'
                                htmlType='button'
                            >
                                詳細を表示{showTopics ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                        </div>
                    </div>
                    {showTopics && (
                        <div className={'d-flex flex-column mx-3 mb-4 meeting-details-requirements'}>
                            <div className={'d-flex row'}>
                                <ul>
                                    {data?.topics?.map((tp, index) => (
                                        <li key={index}>{tp?.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>

                {/* Meeting details inputted by user on meeting schedule page. */}
                <div className={'d-flex flex-column border mb-4 meeting-details-border'}>
                    <div className={'pt-3 mx-3 w-25 meeting-details-remarks-title'}>ご相談内容</div>
                    <div className={'d-flex row py-3 mx-3'}>{data?.meeting?.meeting_remarks || 'ー'}</div>
                </div>

                {/* Comment inputted by corp users when they cancelled the meeting. */}
                {data?.status === ApplicationStatuses.CANCELLED && (
                    <div className={'d-flex flex-column border mt-2 mb-2 meeting-details-border'}>
                        <div className={'d-flex row'}>
                            <div className={'d-flex pt-3 row mx-3 w-75 meeting-details-remarks-title'}>
                                キャンセル理由
                            </div>
                            <div className={'d-flex row align-self-center w-25 justify-content-sm-end mx-4'}>
                                <span>キャンセルした日：{formattedCancellationDate}</span>
                            </div>
                        </div>
                        <div className={'d-flex row py-3 mx-3'}>{data?.cancellation_reason || 'ー'}</div>
                    </div>
                )}

                {/* Show only when role name is super admin and status is interview scheduled */}
                {roleName === KanriDaidoRoleName.SUPER_ADMIN &&
                    data?.status === ApplicationStatuses.INTERVIEW_SCHEDULED && (
                        <div className='meeting-details-action-buttons'>
                            <Row>
                                <Button
                                    onClick={openChangeScheduleModal}
                                    shape={'round'}
                                    className='round-button meeting-details-change-schedule-btn'
                                    htmlType='button'
                                >
                                    予約日程を変更する
                                </Button>

                                <KisokuCancel
                                    uuid={applicationUuid ?? ''}
                                    customerUuid={customerUuid ?? ''}
                                    data={data}
                                />
                            </Row>
                        </div>
                    )}
            </div>

            {/* Modal to change Kisoku meeting schedule */}
            <ChangeScheduleModal
                isVisible={isChangeScheduleModalVisible}
                title='面談予約 日程変更'
                reasonText={
                    '・変更を希望する面談日時を、下のカレンダーから新たに選択してください。\n ・面談予約枠は、本日より7営業日以降の日程から選択可能です。'
                }
                uuid={data?.uuid}
                oldSchedule={data?.meeting?.schedule_datetime}
                onClose={onCloseChangeScheduleModal}
                onOpenChangeScheduleModal={openChangeScheduleModal}
            />
        </>
    );
};

export default KisokuMeetingDetailsBody;
