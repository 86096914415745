import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {RootState} from 'app/store';
import {FetchKisokuMeetingRequest} from "types";
import {getErrorMessage} from "api";
import {cancelMeetingApi, getKisokukiMeetings} from "api/kisoku";
import {Label} from "app/slice/employeeKoutekiSlice";

export type KisokuMeetingState = {
    loading: boolean,
    success: boolean,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
    kisokuMeetings: KisokuMeeting[],
    cancel: boolean | null,
};

export type KisokuMeetingStatus = {
    label: string,
    value: number,
}

export type KisokuMeeting = {
    title?: string,
    scheduleApplied: string,
    scheduleMeeting: string,
    status: number,
    corporateName: string,
    label: Label,
    corpUserId: string,
    uuid: string,
}

type KisokuMeetingData = {
    title?: string,
    schedule_applied: string,
    schedule_meeting: string,
    status: number,
    corporate_name: string,
    label: Label,
    corp_user_id: string,
    uuid: string,
}

export type CancelRequest = {
    uuid: string,
    reason: string,
}

export type ConfirmRequest = {
    title: string,
    datetime: string,
    reason: string,
}

export const fetchKisukuMeetings = createAsyncThunk('daido/kisoku/meeting',
    async (params: FetchKisokuMeetingRequest, {dispatch, rejectWithValue}) => {
        try {
            if (params.status === -1 || params.status === undefined) {
                delete params.status;
            }
            if (params.sortBy === undefined || params.sortBy === '') {
                delete params['sortBy'];
            }
            const response = await getKisokukiMeetings(params);
            const {data = [], pagination = {}, success} = response.data;
            if (success) {
                dispatch(setKisokuMeetingsData(response.data));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const cancelMeeting = createAsyncThunk(
    'daido/meetings/cancel',
    async (request: CancelRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await cancelMeetingApi(request);
            dispatch(setCancelMeeting(response.data));
            return true;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const kisokuMeetingSlice = createSlice({
    name: 'kisoku-meetting',
    initialState: {
        loading: false,
        success: false,
        total: 0,
        perPage: 0,
        currentPage: 0,
        lastPage: 0,
        kisokuMeetings: [],
        cancel: null,
    } as KisokuMeetingState,
    reducers: {
        setKisokuMeetingsData: (state: KisokuMeetingState, {payload}) => {
            state.total = payload.pagination.total;
            state.perPage = payload.pagination.per_page;
            state.currentPage = payload.pagination.current_page;
            state.lastPage = payload.pagination.last_page;
            state.kisokuMeetings = [...payload.data]?.map((record: KisokuMeetingData) => ({
                title: record?.title,
                scheduleApplied: record?.schedule_applied,
                scheduleMeeting: record?.schedule_meeting,
                status: record?.status,
                corporateName: record?.corporate_name,
                label: record?.label,
                corpUserId: record?.corp_user_id,
                uuid: record?.uuid,
            }));
        },
        setCancelMeeting: (state: KisokuMeetingState, {payload}) => {
            state.kisokuMeetings = state.kisokuMeetings.filter((meeting) => meeting.uuid !== payload.uuid);
        },
        resetAfterCancel: (state: KisokuMeetingState) => {
            state.cancel = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchKisukuMeetings.pending, (state: KisokuMeetingState) => {
            state.loading = true;
        });
        builder.addCase(fetchKisukuMeetings.fulfilled, (state: KisokuMeetingState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchKisukuMeetings.rejected, (state: KisokuMeetingState) => {
            state.loading = false;
            state.success = false;
            state.total = 0;
            state.perPage = 0;
            state.currentPage = 0;
            state.lastPage = 0;
            state.kisokuMeetings = [];
        });
        builder.addCase(cancelMeeting.pending, (state: KisokuMeetingState) => {
            state.loading = true;
        });
        builder.addCase(cancelMeeting.fulfilled, (state: KisokuMeetingState) => {
            state.loading = false;
            state.success = true;
            state.cancel = true;
        });
        builder.addCase(cancelMeeting.rejected, (state: KisokuMeetingState) => {
            state.loading = false;
            state.success = false;
            state.cancel = false;
        });
    },
});

export const kisokuMeetingSelector = (state: RootState) => state.kisokuMeeting;
export const {setKisokuMeetingsData, setCancelMeeting, resetAfterCancel} = kisokuMeetingSlice.actions;
